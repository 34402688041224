<template>
    <div>
        
        <v-btn outlined block class="login" @click="login()">
            <img src="../../assets/logos/google.png" alt="google-login-icon" class="google-logo">
            <v-spacer style="max-width: 8%"></v-spacer>
            {{t.login_with_google}}
        </v-btn>
        
    </div>
</template>

<script>
    import auth from "@/modules/auth";
    import i18n from "../../i18n"

    export default {
        name: "GoogleLogin",
        data(){
            return {
            t: {
                login_with_google: i18n.t("message.login_with_google"),
            },
            }
        },
        methods: {
            login() {
                auth.loginWithGoogle();
            }
        }
    }
</script>

<style scoped>
    .login {
        text-transform: None !important;
    }

    .google-logo {
        width: 28px;
     }
</style>