<template>
  <div class="login" v-if="loaded">
    
    <!-- <v-img src="../../assets/grey_background.jpeg" alt="banner" class="banner"></v-img> -->
    <!-- <WelcomeScreen></WelcomeScreen> -->
    <v-container class="login">
      <v-layout row justify-center align-center fill-height>
        <v-flex xs12 sm6>
          <v-hover v-slot:default="{ hover }">
              <v-card flat id="card" class="pa-5 expand-transition" :elevation="hover ? 12 : 5">
                <v-card-text>
                  <b> {{t.welcome_l0}} </b> <br/>
                  <b> {{t.welcome_l1}} </b> <br/>
                  {{t.welcome_l2}} <br/>
                  {{t.welcome_l3}}
                </v-card-text>
                
                <v-divider></v-divider>
                <br/>
                <!-- <v-card-text> Log in or Sign up </v-card-text> -->
                <v-card-text primary-title>
                  <email-login :token="$route.params.token"></email-login>
                  <v-card-text class="mt-2">
                    <span>{{t.or}}</span>
                  </v-card-text>
                  <google-login></google-login>
                  <!-- <facebook-login class="mt-3"></facebook-login> -->
    
                </v-card-text>
              </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
     </v-container>
    
  </div>
</template>

<script>
  import auth from "@/modules/auth";
    // import WelcomeScreen from "../../components/home/WelcomeScreen";
  import GoogleLogin from "../../components/auth/GoogleLogin";
  import EmailLogin from "../../components/auth/EmailLogin";
  // import FacebookLogin from "../../components/auth/FacebookLogin";
  import i18n from '../../i18n'
  
  export default {
    name: 'Login',
    components: {EmailLogin, GoogleLogin},
    data() {
      return {
        t: {
          welcome_l0: i18n.t("message.welcome_l0"),
          welcome_l1: i18n.t("message.welcome_l1"),
          welcome_l2: i18n.t("message.welcome_l2"),
          welcome_l3: i18n.t("message.welcome_l3"),
          or: i18n.t("message.or"),
        },
        loaded: false,
        token: this.$route.params.token
      }
    },
    created() {
      auth.checkAuth().then(() => {
        if (this.$route.params.token) {
          auth.activateAccount(this.$route.params.token).then(() => {
            this.$router.replace('/home');
          }).catch(error => {
            this.$router.replace('/home');
          })
        } else {
          this.$router.replace('/home');
        }
      }).catch(() => {
        this.loaded = true;
      });
    }
  }
</script>

<style scoped>
  .login {
    width: 100%;
    text-align: center;
    height: 100%
  }

  #card {
    margin: 0 5em 0 5em;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.70);
  }

  .banner {
    width: 100vw;
    margin: auto;
    position: fixed;
    height: 100vh;
    top: 0
  }
</style>